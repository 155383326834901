import React, { useContext } from "react"
import Layout from "../../components/layout"

import ThemeContext from "../../context/ThemeContext"
import styles from "./index.module.scss"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import GenericLandingContentWrapper from "../../components/text-helpers/generic-landing-content-wrapper"
import NewsItem from "../../components/news/news-item"
import { Grid } from "@material-ui/core"
import localize from "../../components/localize"
import NullIf from "../../services/NullIf"

const Content = ({ blogPosts, translations, locale }) => {
  return (
    <div className={`${styles.casinoNewsContainer}`}>
      <Grid container xs={12} spacing={0}>
        {blogPosts.filter(x => x.slug && NullIf.isNotNullOrEmpty(x.slug)).map(x => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            xl={3}
            className={`${styles.newsCard}`}
          >
            <NewsItem newsItem={x} locale={locale} />
          </Grid>
        ))}
      </Grid>
    </div>
  )
}

const CasinoNewsIndex = ({ data, pageContext, location }) => {
  const { locale, alternateLocaleSlugs } = pageContext
  let { edges } = data.allSanityBlogPost
  let pageData = data.sanityGenericLandingPage
  let nodes = edges.map(x => x.node).filter(x => x.slug && NullIf.isNotNullOrEmpty(x.slug))

  let { translations } = useContext(ThemeContext)

  return (
    <Layout breadcrumbNameOverride={pageData.breadcrumbName} silo={data.sanitySilo} pageContext={pageContext} location={location}>
      <SEO location={location}
        title={pageData && pageData.seoTitle}
        description={pageData && pageData.seoMeta}
         alternateSlug={alternateLocaleSlugs}
      />
      <GenericLandingContentWrapper pageData={pageData}>
        <Content
          translations={translations}
          locale={locale}
          blogPosts={nodes}
        />
      </GenericLandingContentWrapper>
    </Layout>
  )
}

export default localize(CasinoNewsIndex)
export const query = graphql`
  query CasinoNewsQuery {
      sanitySilo (_id: {eq: "6c6a3bda-364c-482e-a153-4ecd7a12c9de"}){
          _id
          breadcrumbTrail {
              ...LocaleSiloTrails
          }
      }
    allSanityBlogPost(
      sort: { fields: [_updatedAt], order: DESC }
    ) {
      edges {
        node {
          ...BlogPost
        }
      }
    }
    sanityGenericLandingPage(
      name: { eq: "Casino News" }
    ) {
      ...GenericLanding
    }
  }
`
